import { useRef } from "react";
import "./works.css";
import { motion, useScroll } from "framer-motion";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { useTranslation } from "react-i18next";

const Works = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  // const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // const [services, setServices] = useState([]);
  const { t } = useTranslation();
  const { homeTitle2 } = t("home");
  const {
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
    service7,
    service8,
    service9,
    service10,
    service11,
    service12,
  } = t("ServiceTitle");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${backendUrl}api/services`);
  //       setServices(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   fetchData();
  // }, [backendUrl]);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0.9", "1.60 1"],
    animate: { x: 175 },
    initial: { x: -800 },
    transition: { duration: 1.2, ease: "easeOut" },
  });

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <motion.section
      ref={ref}
      style={{
        scale: scrollYProgress,
        opacity: scrollYProgress,
      }}
      className="title-content"
    >
      <div className="titleContainer">
        <div className="row">
          <div className="col-12">
            <div className="title-area">
              <h3 className="subtitle2 h1">{homeTitle2}</h3>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="service-tab-menu row container md-hide">
              <Carousel
                swipeable={true}
                draggable={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                keyBoardControl={true}
                responsive={responsive}
                showDots={true}
                removeArrowOnDeviceType={["tablet"]}
                itemClass="carousel-item-padding-40-px"
                containerClass="carousel-container"
                customTransition="all .5"
                transitionDuration={500}
                infinite={true}
                transition={{
                  type: "spring",
                }}
              >
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() =>
                      navigate("/Özelleştirilmiş Web Uygulamaları")
                    }
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/web.png"
                        alt="Özelleştirilmiş Web Uygulamaları img"
                      />
                    </span>
                    <span className="btn-title h6">{service1}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Mobil Uygulama Geliştirme")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/home-mobile.png"
                        alt="Mobil Uygulama Geliştirme img"
                      />
                    </span>
                    <span className="btn-title h6">{service2}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Veri Analitiği ve Raporlama")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/veri-analitik.png"
                        alt="Veri Analitiği ve Raporlama img"
                      />
                    </span>
                    <span className="btn-title h6">{service3}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Veri Tabanı Ve Sunucu Yönetimi")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/db-and-server.png"
                        alt="Veri Tabanı Ve Sunucu Yönetimi img"
                      />
                    </span>
                    <span className="btn-title h6">{service4}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/A'dan Z'ye Yazılım Desteği")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/support.png"
                        alt="A'dan Z'ye Yazılım Desteği img"
                      />
                    </span>
                    <span className="btn-title h6">{service5}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Kreatif Strateji")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/strategy.png"
                        alt="Kreatif Strateji img"
                      />
                    </span>
                    <span className="btn-title h6">{service6}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Marka Geliştirme")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/marka.png"
                        alt="Marka Geliştirme img"
                      />
                    </span>
                    <span className="btn-title h6">{service7}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Sosyal Medya Yönetimi")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/social-media.png"
                        alt="Sosyal Medya Yönetimi img"
                      />
                    </span>
                    <span className="btn-title h6">{service8}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Reklam Yönetimi")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/reklam-yönetim.png"
                        alt="Reklam Yönetimi img"
                      />
                    </span>
                    <span className="btn-title h6">{service9}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/SEO ve ASO")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/seo-aso.png"
                        alt="SEO ve ASO img"
                      />
                    </span>
                    <span className="btn-title h6">{service10}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Startup Mentörlüğü")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/startup.png"
                        alt="Startup Mentörlüğü img"
                      />
                    </span>
                    <span className="btn-title h6">{service11}</span>
                  </button>
                </div>
                <div>
                  <button
                    className="nav-link-btn"
                    onClick={() => navigate("/Yazılım Eğitimi")}
                  >
                    <span className="btn-img-container">
                      <img
                        className="btn-img"
                        src="/images/software-training.png"
                        alt="Yazılım Eğitimi img"
                      />
                    </span>
                    <span className="btn-title h6">{service12}</span>
                  </button>
                </div>
              </Carousel>
            </div>
            <div className="md-show d-none">
              <div>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  autoPlay={false}
                  autoPlaySpeed={2000}
                  keyBoardControl={true}
                  responsive={responsive}
                  showDots={true}
                  removeArrowOnDeviceType={["tablet", "desktop"]}
                  itemClass="carousel-item-padding-40-px"
                  containerClass="carousel-container"
                  customTransition="all .5"
                  transitionDuration={500}
                  infinite={true}
                  transition={{
                    type: "spring",
                  }}
                >
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() =>
                        navigate("/Özelleştirilmiş Web Uygulamaları")
                      }
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/web.png"
                            alt="Özelleştirilmiş Web Uygulamaları img"
                          />
                        </span>
                        <span className="btn-title h6">{service1}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/Mobil Uygulama Geliştirme")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/home-mobile.png"
                            alt="Mobil Uygulama Geliştirme img"
                          />
                        </span>
                        <span className="btn-title h6">{service2}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/Veri Analitiği ve Raporlama")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/veri-analitik.png"
                            alt="Veri Analitiği ve Raporlama img"
                          />
                        </span>
                        <span className="btn-title h6">{service3}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() =>
                        navigate("/Veri Tabanı Ve Sunucu Yönetimi")
                      }
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/db-and-server.png"
                            alt="Veri Tabanı Ve Sunucu Yönetimi img"
                          />
                        </span>
                        <span className="btn-title h6">{service4}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/A'dan Z'ye Yazılım Desteği")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/support.png"
                            alt="A'dan Z'ye Yazılım Desteği img"
                          />
                        </span>
                        <span className="btn-title h6">{service5}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/Kreatif Strateji")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/strategy.png"
                            alt="Kreatif Strateji img"
                          />
                        </span>
                        <span className="btn-title h6">{service6}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/Marka Geliştirme")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/marka.png"
                            alt="Marka Geliştirme img"
                          />
                        </span>
                        <span className="btn-title h6">{service7}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/Sosyal Medya Yönetimi")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/social-media.png"
                            alt="Sosyal Medya Yönetimi img"
                          />
                        </span>
                        <span className="btn-title h6">{service8}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/Reklam Yönetimi")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/reklam-yönetim.png"
                            alt="Reklam Yönetimi img"
                          />
                        </span>
                        <span className="btn-title h6">{service9}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/SEO ve ASO")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/seo-aso.png"
                            alt="SEO ve ASO img"
                          />
                        </span>
                        <span className="btn-title h6">{service10}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/Startup Mentörlüğü")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/startup.png"
                            alt="Startup Mentörlüğü img"
                          />
                        </span>
                        <span className="btn-title h6">{service11}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ref-carousel-contant">
                    <div
                      className="ref-carousel-container"
                      onClick={() => navigate("/Yazılım Eğitimi")}
                    >
                      <div className="nav-link-btn">
                        <span className="btn-img-container">
                          <img
                            className="btn-img"
                            src="/images/software-training.png"
                            alt="Yazılım Eğitimi img"
                          />
                        </span>
                        <span className="btn-title h6">{service12}</span>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Works;
