import { motion } from "framer-motion";
import "./mision.css";
import { useTranslation } from "react-i18next";

const Mision = () => {
  const { t } = useTranslation();
  const { aboutTitle1, aboutDesc1 } = t("about");
  return (
    <section className="wrapper">
      <div className="row wrapperContainer wrapperMision container position-relative">
        <div className="textContainer col-md-7">
          <motion.div
            animate={{ x: -16 }}
            initial={{ x: -990 }}
            transition={{ duration: 1.2, ease: "easeOut" }}
            className="position-absolute mission-background"
          >
            MISSION
          </motion.div>
          <h1 className="mb-3 mision">{aboutTitle1}</h1>
          <p>{aboutDesc1}</p>
        </div>
        <motion.div
          initial="initial"
          whileHover="whileHover"
          // onMouseMove={handleMouseMove}
          className="wrapperleft col-md-5 misionCol"
        >
          <motion.img
            variants={{
              initial: {
                scale: 0.9,
                rotate: "32.5deg",
                x: 0,
              },
              whileHover: {
                scale: 1,
                rotate: "50.5deg",
                x: [-35, 5, -35],
              },
            }}
            transition={{
              type: "spring",
            }}
            src="/images/misyon.png"
            alt="misyon img"
            className="mision-img"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Mision;
