import React, { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const TeamPerson2 = () => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
  });
  const y = useTransform(scrollYProgress, [0, 1], [-200, 200]);
  const { t } = useTranslation();
  const { teamPosition2, teamDesc2 } = t("team");
  return (
    <section className="section">
      <div className="about-container ">
        <div className="wrapperTeam">
          <motion.div
            ref={ref}
            initial="initial"
            whileHover="whileHover"
            className="imageContainer col-md-6"
          >
            <motion.img
              variants={{
                initial: {
                  scale: 1,
                  rotate: "-1.5deg",
                },
                whileHover: {
                  scale: 1.2,
                  rotate: "5.5deg",
                },
              }}
              transition={{
                type: "spring",
              }}
              src="/images/Emre-guvener.png"
              alt=""
            />
            <div className="team-social-container">
              <p className="text-blue bold mb-2">{teamPosition2}</p>
              <h4 className="mb-2">Emre Güvener</h4>
              <div className="socialIconContainer">
                <a
                  className="me-2 social-icons1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/emre-guvener-08243b3/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  className="me-2 social-icons1"
                  href="mailto:emre@alohalive.online"
                >
                  <i className="fas fa-envelope"></i>
                </a>
              </div>
            </div>
          </motion.div>

          <motion.div
            transition={{
              type: "spring",
            }}
            className="textContainer col-md-6"
            style={{ y }}
          >
            <p>{teamDesc2}</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default TeamPerson2;
