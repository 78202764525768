import { motion } from "framer-motion";
import { Toaster } from "react-hot-toast";
import Modal from "../Modal";
import { useTranslation } from "react-i18next";

const Creativestrategy = () => {
  const { t } = useTranslation();
  const { title, strgDesc, article1, article2, article3, article4, article5 } =
    t("creativestrategy");

  const { infoBtn } = t("moreInfoBtn");
  const defaultAnimations = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <section className="webContant">
      <Toaster />
      <div className="webContainer container">
        <div className="webRow row">
          <div className="col-md-6">
            <img
              className="webImg"
              src="/images/kreatif-strateji.png"
              alt="kreatif strateji"
            />
          </div>
          <div className="col-md-6 webTitleContainer">
            <motion.h2
              initial="hidden"
              animate="visible"
              transition={{ staggerChildren: 0.1 }}
              aria-hidden
              className="webTitle"
            >
              {title.split("").map((char) => (
                <motion.span variants={defaultAnimations}>{char}</motion.span>
              ))}
            </motion.h2>
            <p className="wepDesc">{strgDesc}</p>
            <div className="web-check-lists">
              <ul className="web-list-items">
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article1}</span>
                </li>
                <hr className="web-hr" />
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article2}</span>
                </li>
                <hr className="web-hr" />
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article3}</span>
                </li>
                <hr className="web-hr" />
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article4}</span>
                </li>
                <hr className="web-hr" />
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article5}</span>
                </li>
                <hr className="web-hr" />
              </ul>
            </div>
            <div>
              <button
                type="button"
                class="btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <span className="elementor-button elementor-button-link">
                  <span className="elementor-button-content-wrapper">
                    <span className="elementor-button-text">{infoBtn}</span>
                    <span className="elementor-button-icon">
                      <i class="fa-sharp fa-solid fa-angle-right arrowIcon"></i>
                    </span>
                  </span>
                </span>
              </button>
            </div>
            {/* <!-- Modal --> */}
            <Modal />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Creativestrategy;
