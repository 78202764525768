import React, { useState } from 'react';
import "./webprojects.css";
import ekonomiManset from "./projectImages/ekonomimanset.png";
import psmMag from "./projectImages/psmmag.png";
import sigortaGundem from "./projectImages/sigortagundem.png";
import genesis from "./projectImages/genesis.png";
import klasemlak from "./projectImages/klasemlak.png"
import amerikadanistekler from "./projectImages/amerikadanistekler.png"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const projects = [
    {
        title: "Ekonomi Manşet",
        img: ekonomiManset,
        link: "https://ekonomimanset.com/",
        summary: "Ekonomi Manşet'in web sitesi, modern ve profesyonel tasarımıyla finans dünyasının nabzını tutar. Kullanıcı dostu arayüzüyle ziyaretçilere kolay erişim ve bilgi sunar.",
    },
    {
        title: "PSM Magazine",
        img: psmMag,
        link: "https://www.psmmag.com/",
        summary: "Müşterimizin ihtiyaçlarına odaklanarak, modern ve kullanıcı dostu bir arayüzle, ziyaretçilerine en iyi dijital deneyimi sunmayı hedefledik. İçerik yönetimi, performans optimizasyonu ve mobil uyum gibi birçok teknik detayda titizlikle çalıştık.",
    },
    {
        title: "Sigorta Gündem",
        img: sigortaGundem,
        link: "https://sigortagundem.com/",
        summary: "Türkiye’nin en önemli sigorta haber yayın portalı Sigorta Gündem için modern, hızlı ve kullanıcı dostu bir platform geliştirdik. Kullanıcıların sektörel gelişmeleri daha rahat takip edebilmesi amacıyla sade ve işlevsel bir tasarım oluşturduk.",
    },

    {
        title: "Genesis Health İstanbul",
        img: genesis,
        link: "https://www.genesishealthistanbul.com/",
        summary: "Genesis Health istanbul'un web sitesi, modern ve zarif tasarımıyla sağlık ve estetik dünyasında öncüdür. Kullanıcı dostu arayüzüyle ziyaretçilere rahat ve bilgilendirici bir deneyim sunar.",
    },

    {
        title: "Klas Emlak Gayrimenkul",
        img: klasemlak,
        link: "https://www.klasemlak.com.tr/",
        summary: "Klas Emlak'ın yeni web sitesi, logosu ile uyumlu yeşil tonlarıyla doğanın tazeliğini yansıtan modern bir tasarıma sahip. Kullanıcı dostu arayüzüyle huzur ve güven sunar.",
    }

    ,{
        title: "Amerika'dan İstekler",
        img: amerikadanistekler,
        link: "https://amerikadanistekler.com/",
        summary: "Amerika'dan İstekler'in web sitesi, modern ve kullanışlı tasarımıyla Amerika'dan alışverisi kolaylaştırır. Kullanıcı dostu arayüzüyle ziyaretçilere hızlı ve pratik bir deneyim sunar.",
    }
];

const WebProjects = () => {
    const { t } = useTranslation();
    const { proje2desc, projeTitle2 } = t("home");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [modalClosing, setModalClosing] = useState(false);


    const openModal = (img) => {
        setSelectedImage(img);
        setIsModalOpen(true);
        setModalClosing(false);
    };

    const closeModal = () => {
        setModalClosing(true);
        setTimeout(() => {
            setIsModalOpen(false);
            setSelectedImage(null);
        }, 300);
    };

    const handleClickOutside = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    return (
        <div>
            <h3 className="project-title web-projects-title">Son Projeler</h3>
        <div className='web-projects-container'>
            
        
            {projects.map((project, index) => (
                <div className='web-project-card' key={index}>
                    <div className='web-projects-image-container'>
                        <img
                            className='web-project-card-image'
                            src={project.img}
                            alt={project.title}
                            onClick={() => openModal(project.img)}
                        />
                        
                    </div>
                    <div className='web-project-card-content '>
                        <div>
                    <h3 className='web-project-card-title'>{project.title}</h3>
                    <p className='web-projects-summary'>{project.summary}</p>
                    </div>
                    <Link
                        to={project.link}
                        className="elementor-button elementor-button-link web-project-card-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="elementor-button-content-wrapper">
                            <span className="elementor-button-text">Siteyi Ziyaret Edin</span>
                            <span className="elementor-button-icon">
                                <i className="fa-sharp fa-solid fa-angle-right arrowIcon"></i>
                            </span>
                        </span>
                    </Link>
                    </div>
                </div>
            ))}



            {isModalOpen && (
                <div
                    className={`web-projects-modal ${modalClosing ? 'closing' : 'opening'}`}
                    onClick={handleClickOutside}
                >
                    <div className="web-projects-modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img src={selectedImage} alt="Büyük resim" className="modal-image" />
                    </div>
                </div>
            )}
        </div>
        </div>
    );
}

export default WebProjects;
