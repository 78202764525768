import React, { useEffect } from "react";
import "./wpIcon.css";
import { useTranslation } from "react-i18next";

const Icons = () => {
  const { t } = useTranslation();
  const { desc } = t("wpIcon");

  useEffect(() => {
    const handleMouseEnter = () => {
      document.querySelector(".frame-contact-btn").style.display = "none";
    };

    const handleMouseLeave = () => {
      document.querySelector(".frame-contact-btn").style.display = "block";
    };

    const whatsFloat = document.querySelector(".whats-float");

    whatsFloat.addEventListener("mouseenter", handleMouseEnter);
    whatsFloat.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      whatsFloat.removeEventListener("mouseenter", handleMouseEnter);
      whatsFloat.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);
  return (
    <>
      <div className="frame-contact">
        <a
          href="https://wa.me/908507579427"
          className="frame-contact-btn"
          type="button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            {desc}
            <img className="emoji" src="./images/handicon.png" alt="" />
          </span>
        </a>
      </div>
      <div className="whats-float">
        <a
          href="https://wa.me/908507579427"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa-brands fa-whatsapp"></i>
          <span>
            WhatsApp
            <br />
            <small>0850 757 94 27</small>
          </span>
        </a>
      </div>
    </>
  );
};

export default Icons;
