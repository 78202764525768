import { useEffect, useState } from "react";
import AnimatedCursor from "react-animated-cursor";

const AnimeCursor = () => {
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      // Ekran genişliği 600 pikselden küçükse, cursor animasyonunu gizle
      setShowCursor(window.innerWidth > 600);
    };

    handleResize(); // İlk renderda kontrol et

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const cursorStyle = {
    zIndex: 99999, // Modal'dan daha büyük bir z-index değeri
  };

  return showCursor ? (
    <AnimatedCursor
      outerStyle={cursorStyle}
      innerSize={8}
      outerSize={20}
      color="105, 72, 185"
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={3}
      clickables={[
        "a",
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        "label[for]",
        "select",
        "textarea",
        "button",
        ".link",
        "modal",
        "Close",
      ]}
    />
  ) : null;
};

export default AnimeCursor;
