import React, { Suspense, useState } from "react";
import styled from "styled-components";
import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import Cube from "../cube/Cube";
import { useTranslation } from "react-i18next";
import "./model.css";
import InfoModal from "../InfoModal";

const Section = styled.div`
  /* height: 100vh; */
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  /* height: 100vh; */
  scroll-snap-align: center;
  width: 1400px;
  display: flex;
  justify-content: space-between;
  margin-top: -70px;
  height: 600px !important;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto !important;
  }
`;

const Left = styled.div`
  flex: 1;

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 30px; */
    margin-bottom: -103px;
  }
`;

const Title = styled.h1`
  font-size: 30px;

  @media only screen and (max-width: 768px) {
    font-size: 60px;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }
`;

const WhatWeDo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Button = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Model = () => {
  const { t } = useTranslation();
  const [modalIndex, setModalIndex] = useState(0);
  const {
    trainingBtn,
    cubeTitle,
    cubeArticle1,
    cubeArticle2,
    cubeArticle3,
    cubeArticle4,
    cubeDesc1,
    cubeDesc2,
    cubeDesc3,
    cubeDesc4,
  } = t("softwareTraining");

  const modelTitles = [cubeArticle1, cubeArticle2, cubeArticle3, cubeArticle4];

  const modelContents = [cubeDesc1, cubeDesc2, cubeDesc3, cubeDesc4];

  const handleModalChange = (index) => {
    setModalIndex(index);
  };

  return (
    <Section className="container">
      <Container>
        <Left>
          <Canvas className="canvasCube">
            <Suspense fallback={null}>
              <ambientLight intensity={1.5} />
              <directionalLight position={[2, 2, 2]} />
              <Cube />
              <OrbitControls enableZoom={false} autoRotate />
            </Suspense>
          </Canvas>
        </Left>
        <Right>
          <h2 className="webTitle">{cubeTitle}</h2>
          <WhatWeDo>
            <ul className="web-list-items">
              {modelTitles.map((title, index) => (
                <>
                  <li className="web-list-item">
                    <span className="web-list-icon">
                      <i class="fa-solid fa-circle-check"></i>
                    </span>
                    <Button
                      key={index}
                      onClick={() => handleModalChange(index)}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <span className="web-list-desc">{title}</span>
                      <span className="elementor-button-icon">
                        <i class="fa-sharp fa-solid fa-angle-right arrowIcon"></i>
                      </span>
                    </Button>
                  </li>
                  <hr className="web-hr" />
                </>
              ))}
            </ul>
            <InfoModal
              title={modelTitles[modalIndex]}
              content={modelContents[modalIndex]}
            />
          </WhatWeDo>
          <div>
            <a
              href="https://www.alohadijitalakademi.com/"
              target="_blank"
              rel="noopener noreferrer"
              type="button"
              class="btn"
            >
              <span className="elementor-button elementor-button-link">
                <span className="elementor-button-content-wrapper">
                  <span className="elementor-button-text">{trainingBtn}</span>
                  <span className="elementor-button-icon">
                    <i class="fa-solid fa-arrow-right arrowIcon"></i>
                  </span>
                </span>
              </span>
            </a>
          </div>
        </Right>
      </Container>
    </Section>
  );
};

export default Model;
