import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./pages/navbar/Navbar";
import Footer from "./pages/footer/Footer";
import AnimatedRoutes from "./components/AnimatedRoutes";
import WpIcon from "./components/icons/WpIcon";
import AnimeCursor from "./components/AnimeCursor";

function App() {
  return (
    <div className="App">
      <Router>
        <AnimeCursor />
        <Navbar />
        <WpIcon />
        <AnimatedRoutes />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
