import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Modal = () => {
  const { t } = useTranslation();
  const { contactTitle2, firstName, lastName, mail, message, submit } =
    t("contact");

  const { popupDesc } = t("formPopup");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${backendUrl}contact`, formData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
      toast(popupDesc, {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "rgb(81, 42, 173)",
          color: "#fff",
          fontSize: "13px",
        },
      });
    } catch (error) {
      console.log("Form gönderme hatası", error);
      toast.error("Bir şeyler ters gitti, lütfen tekrar deneyiniz!", error);
    }

    console.log(formData);
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {contactTitle2}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="formContainer">
              <div className="formBorder">
                <form
                  id="fcf-form-id"
                  className="fcf-form-class"
                  onSubmit={handleSubmit}
                >
                  <div className="fcf-form-group">
                    <div className="fcf-input-group">
                      <input
                        type="text"
                        id="Name"
                        name="firstName"
                        className="fcf-form-control"
                        placeholder={firstName}
                        required
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="fcf-form-group">
                    <div className="fcf-input-group">
                      <input
                        type="text"
                        id="Soyadi"
                        name="lastName"
                        className="fcf-form-control"
                        placeholder={lastName}
                        required
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="fcf-form-group">
                    <div className="fcf-input-group">
                      <input
                        type="email"
                        id="Email"
                        name="email"
                        className="fcf-form-control"
                        placeholder={mail}
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="fcf-form-group">
                    <div className="fcf-input-group">
                      <textarea
                        id="Message"
                        name="message"
                        className="fcf-form-control"
                        rows="6"
                        maxlength="3000"
                        placeholder={message}
                        required
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="fcf-form-group">
                    <button
                      type="submit"
                      id="fcf-button"
                      className="formButton"
                    >
                      {submit}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
