// import { PerspectiveCamera, RenderTexture, Text } from "@react-three/drei";
import { Text } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { useTranslation } from "react-i18next";
import { TextureLoader } from "three";

const Cube = () => {
  const { t } = useTranslation();

  const { cubeArticle5, cubeArticle6, cubeArticle7, cubeArticle8 } =
    t("softwareTraining");

  const texture_1 = useLoader(TextureLoader, "/images/staj.png");
  const texture_2 = useLoader(TextureLoader, "/images/iş-imkanı.png");
  const texture_3 = useLoader(TextureLoader, "/images/project.png");
  const texture_4 = useLoader(TextureLoader, "/images/teknik-egitim.png");
  const cubeSize = 2.5; // Küp boyutu
  return (
    <group>
      <mesh>
        <boxGeometry args={[cubeSize, cubeSize, cubeSize]} />
        <meshStandardMaterial map={texture_1} attach="material-0" />
        <meshStandardMaterial map={texture_2} attach="material-1" />
        <meshStandardMaterial map={texture_3} attach="material-2" />
        <meshStandardMaterial map={texture_4} attach="material-3" />
        <meshStandardMaterial map={texture_3} attach="material-4" />
        <meshStandardMaterial map={texture_4} attach="material-5" />
      </mesh>
      {/* Ön Yüz */}
      <Text
        position={[0, -0.6, cubeSize / 1.96]}
        fontSize={0.36}
        color="#310f80"
        anchorX="center"
        anchorY="middle"
      >
        {cubeArticle6}
      </Text>
      {/* Arka Yüz */}
      <Text
        position={[0, -0.39, cubeSize / -1.99]}
        fontSize={0.36}
        color="#310f80"
        anchorX="center"
        anchorY="end"
        rotation={[0, Math.PI, 0]}
      >
        {cubeArticle5}
      </Text>
      {/* Üst Yüz */}
      <Text
        position={[0, 1.26, cubeSize / 7]}
        fontSize={0.36}
        color="#310f80"
        anchorX="center"
        anchorY="end"
        rotation={[-Math.PI / 2, 0, 0]}
      >
        {cubeArticle6}
      </Text>
      {/* Alt Yüz */}
      <Text
        position={[0, -1.27, -0.5]}
        fontSize={0.36}
        color="#310f80"
        anchorX="center"
        anchorY="middle"
        rotation={[1.6, 0, 0 / -Math.PI]}
      >
        {cubeArticle5}
      </Text>
      {/* Sol Yüz */}
      <Text
        position={[-1.26, -0.6, 0]}
        fontSize={0.36}
        color="#310f80"
        anchorX="center"
        anchorY="middle"
        rotation={[0, -Math.PI / 2, 0]}
      >
        {cubeArticle8}
      </Text>
      {/* Sağ Yüz */}
      <Text
        position={[1.26, -0.6, 0]}
        fontSize={0.36}
        color="#310f80"
        anchorX="center"
        anchorY="middle"
        rotation={[0, Math.PI / 2, 0]}
      >
        {cubeArticle7}
      </Text>
    </group>
  );
};

export default Cube;
