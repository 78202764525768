import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Model from "./Model";

const SoftwareTraining = () => {
  const { t } = useTranslation();
  const {
    title,
    softwareTrainingDesc,
    article1,
    article2,
    article3,
    article4,
    article5,
  } = t("softwareTraining");

  const defaultAnimations = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <section className="webContant">
      <div className="webContainer container">
        <div className="webRow row">
          <div className="col-md-6 training">
            <img
              className="webImg"
              src="/images/softwareTraining.png"
              alt="Yazılım Eğitimi"
            />
          </div>
          <div className="col-md-6 webTitleContainer">
            <motion.h2
              initial="hidden"
              animate="visible"
              transition={{ staggerChildren: 0.1 }}
              aria-hidden
              className="webTitle"
            >
              {title.split("").map((char) => (
                <motion.span variants={defaultAnimations}>{char}</motion.span>
              ))}
            </motion.h2>
            <p className="wepDesc">{softwareTrainingDesc}</p>
            <div className="web-check-lists">
              <ul className="web-list-items">
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article1}</span>
                </li>
                <hr className="web-hr" />
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article2}</span>
                </li>
                <hr className="web-hr" />
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article3}</span>
                </li>
                <hr className="web-hr" />
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article4}</span>
                </li>
                <hr className="web-hr" />
                <li className="web-list-item">
                  <span className="web-list-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <span className="web-list-desc">{article5}</span>
                </li>
                <hr className="web-hr" />
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Model />
    </section>
  );
};

export default SoftwareTraining;
