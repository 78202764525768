import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./languages/i18n.js";
import ReactGA from "react-ga4";

ReactGA.initialize("G-Y27ET00019");
ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading...">
      <App />
    </React.Suspense>
  </React.StrictMode>
);

reportWebVitals();
