import "./references.css";
import { motion } from "framer-motion";
import { Canvas } from "@react-three/fiber";
import { MeshDistortMaterial, OrbitControls, Sphere } from "@react-three/drei";
import { Suspense, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

const References = () => {
  const { t, i18n } = useTranslation();
  const [centerImage, setCenterImage] = useState(t("refCenterImage"));
  const {
    refTitle,
    refTitleMore,
    refDesc,
    refBtn,
    refTitle2,
    refTitleMore2,
    liveTitle,
    liveTitleDesc,
    liveTitle2,
    liveTitleDesc2,
    liveTitle3,
    liveTitleDesc3,
    liveTitle4,
    liveTitleDesc4,
    liveTitle5,
    liveTitleDesc5,
    liveTitle6,
    liveTitleDesc6,
  } = t("references");

  const images = {
    en: {
      appHesap: "/images/acountIconEn.png",
      dinleyici: "/images/ListeningiconEn.png",
      konusmaci: "/images/speakerIconEn.png",
      coinAl: "/images/shopIconEn.png",
      kuponAl: "/images/couponIconEn.png",
      hediyeGonder: "/images/giftIconEn.png",
    },
    tr: {
      appHesap: "/images/appHesap.png",
      dinleyici: "/images/dinleyici-Img.png",
      konusmaci: "/images/konusmaci-img.png",
      coinAl: "/images/coinAl-img.png",
      kuponAl: "/images/kuponAl-img.png",
      hediyeGonder: "/images/hediyeGonder-img.png",
    },
  };

  const handleHover = (imageKey) => {
    const newImage = images[i18n.language][imageKey];
    setCenterImage(newImage);
  };

  useEffect(() => {
    setCenterImage(t("refCenterImage"));
  }, [i18n.language, t]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <section className="homeContant">
        <div className="row  refContainer">
          <motion.div
            initial="hidden"
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            animate="visible"
            transition={{ duration: 0.5, delay: 0.25 }}
            className=" refhomeleft col-12 col-sm-12 col-md-6 position-relative"
          >
            <motion.h1
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              animate="visible"
              transition={{ duration: 0.5, delay: 0.25 }}
              className="refTitle"
            >
              <font style={{ color: "#512AAD", fontWeight: "bold" }}>
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>{refTitle}</font>
                </font>
              </font>
              <br />
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>{refTitleMore}</font>
              </font>
            </motion.h1>
            <motion.p
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              animate="visible"
              transition={{ duration: 0.5, delay: 0.25 }}
              className="homeDesc refDesc"
            >
              {refDesc}
            </motion.p>
            <div className="app-dowload-area">
              <div className="row align-items-center dowload">
                <div className="col-md-6">
                  <table>
                    <tbody>
                      <tr>
                        <td rowSpan={2} className="qrContainer">
                          <img
                            className="qrcode mr-2"
                            src="/images/appQR.png"
                            alt="qrcode"
                          />
                        </td>
                        <td>
                          <a
                            className="applestor"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://apps.apple.com/tr/app/aloha-live-anonim-sohbet/id1454225692?l=tr"
                          >
                            <img src="/images/appstore_tr.png" alt="appstore" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.alohachat&hl=tr&gl=US"
                          >
                            <img
                              className="googleplay"
                              src="/images/googleplay_tr.png"
                              alt="googleplay"
                            />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6 refMoreBtn">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="elementor-button elementor-button-link"
                    href="https://www.alohaliveapp.com/"
                  >
                    <span class="elementor-button-content-wrapper">
                      <span class="elementor-button-text">{refBtn}</span>
                      <span class="elementor-button-icon">
                        <i class="fa-sharp fa-solid fa-angle-right arrowIcon"></i>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            animate={{ x: 60 }}
            initial={{ x: 800 }}
            transition={{ duration: 1.2, ease: "easeOut" }}
            className="col-12 col-sm-12 col-md-6 img-container ref-img-container"
          >
            <Canvas>
              <Suspense fallback={null}>
                <OrbitControls enableZoom={false} />
                <ambientLight intensity={1} />
                <directionalLight position={[3, 1, 3]} />
                <Sphere args={[1, 100, 200]} scale={2.7}>
                  <MeshDistortMaterial
                    color="rgb(124, 93, 196)"
                    attach="material"
                    distort={0.4}
                    speed={3}
                  />
                </Sphere>
              </Suspense>
            </Canvas>
            <img
              className=" homeImg"
              src="/images/alohaappinsan.png"
              alt="app insan img"
            />
          </motion.div>
        </div>
      </section>
      <section className="appHome">
        <div className="container refContant">
          <div className="text-center">
            <h1 className="ref-h1">
              <span className="aloha">{refTitle2}</span>
              <br />
              {refTitleMore2}
            </h1>
          </div>
          <div className="row p-ref">
            <div
              className="md-hide col-md-4 no-padding"
              onMouseEnter={() => handleHover("appHesap")}
            >
              <div className="card mobile-card taplet-card">
                <img
                  className="card-icon ref-card-icon"
                  src="/images/acountIcon.png"
                  alt="account icon"
                />
                <div className="card-body mt-0">
                  <h5 className="card-title">{liveTitle3}</h5>
                  <p className="card-text">{liveTitleDesc3}</p>
                </div>
              </div>
              <div
                className="card mobile-card taplet-card"
                onMouseEnter={() => handleHover("dinleyici")}
              >
                <img
                  className="card-icon ref-card-icon icon-listen"
                  src="/images/Listeningicon.png"
                  alt="dinleyici icon"
                />
                <div className="card-body listen-body mt-0">
                  <h5 className="card-title">{liveTitle4}</h5>
                  <p className="card-text">{liveTitleDesc4}</p>
                </div>
              </div>
              <div
                className="card mobile-card taplet-card"
                onMouseEnter={() => handleHover("konusmaci")}
              >
                <img
                  className="card-icon ref-card-icon icon-listen"
                  src="/images/speakerIcon.png"
                  alt="konusmaci icon"
                />
                <div className="card-body mt-0">
                  <h5 className="card-title">{liveTitle5}</h5>
                  <p className="card-text">{liveTitleDesc5}</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center justify-content-center d-flex align-self-center h-100 ">
              <img
                src={centerImage}
                alt="aloha live da hesap oluştur"
                className="mobileImage"
              />
            </div>
            <div
              className="md-hide col-md-4"
              onMouseEnter={() => handleHover("coinAl")}
            >
              <div className="card mobile-card taplet-card">
                <img
                  className="card-icon ref-card-icon icon-shop"
                  src="/images/shopIcon.png"
                  alt="shop icon"
                />
                <div className="card-body mt-0">
                  <h5 className="card-title">{liveTitle6}</h5>
                  <p className="card-text">{liveTitleDesc6}</p>
                </div>
              </div>
              <div
                className="card mobile-card taplet-card"
                onMouseEnter={() => handleHover("kuponAl")}
              >
                <img
                  className="card-icon ref-card-icon icon-coupon"
                  src="/images/couponIcon.png"
                  alt="kupon img"
                />
                <div className="card-body mt-0">
                  <h5 className="card-title">{liveTitle}</h5>
                  <p className="card-text">{liveTitleDesc}</p>
                </div>
              </div>
              <div
                className="card mobile-card taplet-card"
                onMouseEnter={() => handleHover("hediyeGonder")}
              >
                <img
                  className="card-icon ref-card-icon icon-gift"
                  src="/images/giftIcon.png"
                  alt="gift icon"
                />
                <div className="card-body mt-0">
                  <h5 className="card-title">{liveTitle2}</h5>
                  <p className="card-text">{liveTitleDesc2}</p>
                </div>
              </div>
            </div>
            <div className="md-show d-none">
              <div>
                <div>
                  <div>
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      autoPlay={false}
                      autoPlaySpeed={2000}
                      keyBoardControl={true}
                      responsive={responsive}
                      showDots={true}
                      removeArrowOnDeviceType={["tablet", "desktop"]}
                      itemClass="carousel-item-padding-40-px"
                      containerClass="carousel-container"
                      customTransition="all .5"
                      transitionDuration={500}
                      infinite={true}
                      transition={{
                        type: "spring",
                      }}
                    >
                      <div className="ref-carousel-contant">
                        <div
                          className="ref-carousel-container"
                          onMouseEnter={() => handleHover("appHesap")}
                        >
                          <img
                            className="card-icon ref-card-icon"
                            src="/images/acountIcon.png"
                            alt="giris icon"
                          />
                          <div className="card-body mt-0">
                            <h5 className="card-title">{liveTitle3}</h5>
                            <span className="card-text">{liveTitleDesc3}</span>
                          </div>
                        </div>
                      </div>
                      <div className="ref-carousel-contant">
                        <div
                          className="ref-carousel-container"
                          onMouseEnter={() => handleHover("dinleyici")}
                        >
                          <img
                            className="card-icon ref-card-icon icon-listen"
                            src="/images/Listeningicon.png"
                            alt="dinleyici icon"
                          />
                          <div className="card-body listen-body mt-0">
                            <h5 className="card-title">{liveTitle4}</h5>
                            <p className="card-text">{liveTitleDesc4}</p>
                          </div>
                        </div>
                      </div>
                      <div className="ref-carousel-contant">
                        <div
                          className="ref-carousel-container"
                          onMouseEnter={() => handleHover("konusmaci")}
                        >
                          <img
                            className="card-icon icon-listen ref-card-icon"
                            src="/images/speakerIcon.png"
                            alt="konusmaci icon"
                          />
                          <div className="card-body mt-0">
                            <h5 className="card-title">{liveTitle5}</h5>
                            <p className="card-text">{liveTitleDesc5}</p>
                          </div>
                        </div>
                      </div>
                      <div className="ref-carousel-contant">
                        <div
                          className="ref-carousel-container"
                          onMouseEnter={() => handleHover("coinAl")}
                        >
                          <img
                            className="card-icon icon-shop ref-card-icon"
                            src="/images/shopIcon.png"
                            alt="shop icon"
                          />
                          <div className="card-body mt-0">
                            <h5 className="card-title">{liveTitle6}l</h5>
                            <p className="card-text">{liveTitleDesc6}</p>
                          </div>
                        </div>
                      </div>
                      <div className="ref-carousel-contant">
                        <div
                          className="ref-carousel-container"
                          onMouseEnter={() => handleHover("kuponAl")}
                        >
                          <img
                            className="card-icon icon-coupon ref-card-icon"
                            src="/images/couponIcon.png"
                            alt="kupon img"
                          />
                          <div className="card-body mt-0">
                            <h5 className="card-title">{liveTitle}</h5>
                            <p className="card-text">{liveTitleDesc}</p>
                          </div>
                        </div>
                      </div>
                      <div className="ref-carousel-contant">
                        <div
                          className="ref-carousel-container"
                          onMouseEnter={() => handleHover("hediyeGonder")}
                        >
                          <img
                            className="card-icon icon-gift ref-card-icon"
                            src="/images/giftIcon.png"
                            alt="hediye icon"
                          />
                          <div className="card-body mt-0">
                            <h5 className="card-title">{liveTitle2}</h5>
                            <p className="card-text">{liveTitleDesc2}</p>
                          </div>
                        </div>
                      </div>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default References;
