import "./home.css";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { MeshDistortMaterial, OrbitControls, Sphere } from "@react-three/drei";
import { Suspense } from "react";
import Works from "../works/Works";
import Project1 from "../works/proje1/Project1";
import Project2 from "../works/proje2/Project2";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Home = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const controls = useAnimation(); // Burada kontrolcüyü tanımlıyoruz
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { t } = useTranslation();
  const {
    homeTitle0,
    homeTitle1,
    homedesc1,
    proje1desc,
    academyDesc,
    homeContactBtn,
    homeTitle3,
    projeTitle1,
    homeButton,
    homeTitle4,
    homeTitle5,
    homeTitle6,
  } = t("home");

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const elementTop = ref.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight * 0.9) {
          controls.start({ x: 175 }); // Kontrolcüyü burada kullanıyoruz
        } else {
          controls.start({ x: -500 }); // Kontrolcüyü burada kullanıyoruz
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls]);

  return (
    <motion.div
      // ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      // animate={isInView && "animate"}
    >
      <section className="homeContant">
        <div className="row align-items-center homeContainer">
          <motion.div
            initial="hidden"
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            animate="visible"
            transition={{ duration: 0.5, delay: 0.25 }}
            className=" homeleft col-12 col-sm-12 col-md-6 position-relative"
          >
            <motion.h1
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              animate="visible"
              transition={{ duration: 0.5, delay: 0.25 }}
              className="homeTitle"
            >
              <p style={{ color: "#512AAD", fontWeight: "bold" }}>
                {homeTitle0}
              </p>
              <p className="homeTitlep" style={{ verticalAlign: "inherit" }}>
                {homeTitle1}
              </p>
            </motion.h1>
            <motion.p
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              animate="visible"
              transition={{ duration: 0.5, delay: 0.25 }}
              className="homeDesc"
            >
              {homedesc1}
            </motion.p>
            <div className="btn-content">
              <motion.button
                whileHover={{
                  scale: 1,
                  // background: " rgb(252, 204, 60)",
                }}
                whileTap={{ scale: 0.95 }}
                transition={{
                  duration: 0.125,
                  ease: "easeInOut",
                }}
                className="home-btn"
                onClick={() => navigate("/contact")}
              >
                {homeContactBtn}
              </motion.button>
            </div>
          </motion.div>
          <motion.div
            animate={{ x: 60 }}
            initial={{ x: 800 }}
            transition={{ duration: 1.2, ease: "easeOut" }}
            className="col-12 col-sm-12 col-md-6 img-container"
          >
            <Canvas>
              <Suspense fallback={null}>
                <OrbitControls enableZoom={false} />
                <ambientLight intensity={1} />
                <directionalLight position={[3, 1, 3]} />
                <Sphere args={[1, 100, 200]} scale={2.7}>
                  <MeshDistortMaterial
                    color="rgb(124, 93, 196)"
                    attach="material"
                    distort={0.4}
                    speed={3}
                  />
                </Sphere>
              </Suspense>
            </Canvas>
            <img
              className="h-100 homeImg"
              src="/images/home-page.png"
              alt="aloha dijital yazılım"
            />
          </motion.div>
        </div>
      </section>
      <Works />
      <section className="works-contant">
        <div className="works-container">
          <div className="title-area">
            <h3 className="subtitle3 h1">{homeTitle3}</h3>
          </div>
          <div className="row align-items-center justify-content-flex-start headline2">
            <motion.div
              ref={ref}
              animate={controls}
              initial={{ x: isMobile ? -200 : -300 }}
              transition={{ duration: isMobile ? 0.5 : 1.7, ease: "easeOut" }}
              className="col-12 col-sm-12 col-md-6 img-container2 img-content order-sm-last order-lg-first"
            >
              <img
                className="h-100"
                src="/images/dinleyici-homepage.png"
                alt=""
              />
            </motion.div>
            <div className="col-12 col-sm-12 col-md-6 pt-4 home-desc order-sm-first order-lg-last">
              <h3 className="project-title">{projeTitle1}</h3>
              <p className="desc">{proje1desc}</p>
              <div className="elementor-content" href="">
                <Link
                  to="/ref"
                  className="elementor-button elementor-button-link"
                >
                  <span className="elementor-button-content-wrapper">
                    <span className="elementor-button-text">{homeButton}</span>
                    <span className="elementor-button-icon">
                      <i class="fa-sharp fa-solid fa-angle-right arrowIcon"></i>
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <hr className="hr" />
          <Project1 />
          <hr className="hr" />
          <Project2 />
        </div>
      </section>
      <div className="title-area">
        <h3 className="subtitle3 akademi-title h1">{homeTitle4}</h3>
      </div>
      <section className="homeContant">
        <div className="row align-items-center homeContainer">
          <div className=" homeleft col-12 col-sm-12 col-md-6 position-relative">
            <h3 className="homeTitle akademiTitle">
              <p style={{ color: "#512AAD", fontWeight: "bold" }}>
                {homeTitle5}
              </p>
              <p className="homeTitlep" style={{ verticalAlign: "inherit" }}>
                {homeTitle6}
              </p>
            </h3>
            <p className="homeDesc">{academyDesc}</p>
            <div className="btn-content">
              <motion.button
                whileHover={{
                  scale: 1,
                  // background: " rgb(252, 204, 60)",
                }}
                whileTap={{ scale: 0.95 }}
                transition={{
                  duration: 0.125,
                  ease: "easeInOut",
                }}
                className="home-btn"
                onClick={() => navigate("/Yazılım Eğitimi")}
              >
                {homeButton}
              </motion.button>
            </div>
          </div>
          <motion.div
            animate={{ x: 60 }}
            initial={{ x: 800 }}
            transition={{ duration: 1.2, ease: "easeOut" }}
            className="col-12 col-sm-12 col-md-6 img-container"
          >
            <Canvas>
              <Suspense fallback={null}>
                <OrbitControls enableZoom={false} />
                <ambientLight intensity={1} />
                <directionalLight position={[3, 1, 3]} />
                <Sphere args={[1, 100, 200]} scale={2.7}>
                  <MeshDistortMaterial
                    color="rgb(124, 93, 196)"
                    attach="material"
                    distort={0.4}
                    speed={3}
                  />
                </Sphere>
              </Suspense>
            </Canvas>
            <img
              className="h-100 homeImg"
              src="/images/softwareTraining-home.png"
              alt=""
            />
          </motion.div>
        </div>
      </section>
    </motion.div>
  );
};

export default Home;
