import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../pages/home/Home";
import AboutUs from "../pages/about-us/AboutUs";
import References from "../pages/references/References";
import Contact from "../pages/contact/Contact";
import { AnimatePresence } from "framer-motion";
import Web from "../pages/services/webServices/Web";
import Mobile from "../pages/services/mobileServices/Mobile";
import DataAnalytics from "../pages/services/dataAnalytics/DataAnalytics";
import DbServer from "../pages/services/dbAndServer/DbServer";
import SoftwareSupport from "../pages/services/softwareSupport/SoftwareSupport";
import Creativestrategy from "../pages/services/creativestrategy/Creativestrategy";
import BrandDevelopment from "../pages/services/brandDevelopment/BrandDevelopment";
import AdvertisingManagement from "../pages/services/advertisingManagement/AdvertisingManagement";
import SocialMedia from "../pages/services/socialMedia/SocialMedia";
import SeoAso from "../pages/services/seo-aso/SeoAso";
import StartUp from "../pages/services/startup/StartUp";
import SoftwareTraining from "../pages/services/softwareTraining/SoftwareTraining";
import WebProjects from "../pages/webProjects/WebProjects";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const AnimatedRoutes = () => {
  return (
    <AnimatePresence>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/ref" element={<References />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Özelleştirilmiş Web Uygulamaları" element={<Web />} />
        <Route path="/Mobil Uygulama Geliştirme" element={<Mobile />} />
        <Route
          path="/Veri Analitiği Ve Raporlama"
          element={<DataAnalytics />}
        />
        <Route path="/Veri Tabanı Ve Sunucu Yönetimi" element={<DbServer />} />
        <Route
          path="/A'dan Z'ye Yazılım Desteği"
          element={<SoftwareSupport />}
        />
        <Route path="/Kreatif Strateji" element={<Creativestrategy />} />
        <Route path="/Marka Geliştirme" element={<BrandDevelopment />} />
        <Route path="/Sosyal Medya Yönetimi" element={<SocialMedia />} />
        <Route path="/Reklam Yönetimi" element={<AdvertisingManagement />} />
        <Route path="/SEO ve ASO" element={<SeoAso />} />

        <Route path="/Startup Mentörlüğü" element={<StartUp />} />
        <Route path="/Yazılım Eğitimi" element={<SoftwareTraining />} />
        <Route path="/web-uygulamalari" element={<WebProjects />} />
      
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
