import React, { useRef } from "react";
import { useScroll, useSpring } from "framer-motion";
import { motion } from "framer-motion";
import "./aboutUs.css";
import Mision from "./Mision";
import Vision from "./Vision";
import { useTranslation } from "react-i18next";
import TeamPerson1 from "./TeamPerson1";
import TeamPerson2 from "./TeamPerson2";
import TeamPerson3 from "./TeamPerson3";

const AboutUs = () => {
  const ref = useRef();
  const { t } = useTranslation();
  const { meetTitle } = t("meetUs");
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 50,
  });

  // const [teams, setTeams] = useState([]);
  // const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${backendUrl}api/team`);
  //       setTeams(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   fetchData();
  // }, [backendUrl]);

  return (
    <>
      <Mision />
      <Vision />
      <div className="portfolio">
        <div className="about-progress">
          <h1>{meetTitle}</h1>
          <motion.div
            style={{ scaleX }}
            className="about-progressBar"
          ></motion.div>
        </div>
        <div className="section-container">
          <TeamPerson1 />
          <TeamPerson2 />
          <TeamPerson3 />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
