import { useTranslation } from "react-i18next";

const Languages = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <button className="btn btn-sm" onClick={() => changeLanguage("tr")}>
        {t("TR")}
      </button>
      <button className="btn btn-sm" onClick={() => changeLanguage("en")}>
        {t("EN")}
      </button>
    </>
  );
};

export default Languages;
