import { motion } from "framer-motion";
import "./vision.css";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Vision = () => {
  const ref = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { t } = useTranslation();
  const { aboutTitle2, aboutDesc2 } = t("about");

  return (
    <section className="wrapper">
      <div className="row wrapperContainer container">
        <motion.div
          initial="initial"
          whileHover="whileHover"
          className=" col-md-5 visionCol"
        >
          <motion.img
            variants={{
              initial: {
                scale: 0.9,
                rotate: "-5.5deg",
              },
              whileHover: {
                scale: 1,
                rotate: "8.5deg",
              },
            }}
            transition={{
              type: "spring",
            }}
            src="/images/vizyon.png"
            alt="vizyon img"
            className="vision-img"
          />
        </motion.div>

        <div className="textContainer col-md-7 position-relative">
          <motion.div
            ref={ref}
            animate={{ x: -13 }}
            initial={{ x: isMobile ? 400 : 880 }}
            transition={{ duration: isMobile ? 0.6 : 1.2, ease: "easeOut" }}
            className="position-absolute vision-background"
          >
            VISION
          </motion.div>
          <h1 className="mb-3 vizion">{aboutTitle2}</h1>
          <p>{aboutDesc2}</p>
        </div>
      </div>
    </section>
  );
};

export default Vision;
